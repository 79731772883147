.out-500-10 {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 10px;
}

.out-500-12 {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 12px;
}
.out-500-14 {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.out-500-16 {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.out-500-18 {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.out-500-20 {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.out-600-8 {
  font-family: Outfit, sans-serif;
  font-weight: 600;
  font-size: 8px;
}

.out-600-20 {
  font-family: Outfit, sans-serif;
  font-weight: 600;
  font-size: 20px;
}
.out-600-30 {
  font-family: Outfit, sans-serif;
  font-weight: 600;
  font-size: 30px;
}
.out-600-12 {
  font-family: Outfit, sans-serif;
  font-weight: 600;
  font-size: 12px;
}
.out-600-14 {
  font-family: Outfit, sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.out-600-16 {
  font-family: Outfit, sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.out-600-18 {
  font-family: Outfit, sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.out-600-24 {
  font-family: Outfit, sans-serif;
  font-weight: 600;
  font-size: 24px;
}
.out-500-20 {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.out-500-24 {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.out-300-24 {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
}
.out-500-30 {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
}

.out-400-12 {
  font-family: Outfit, sans-serif;
  font-weight: 400;
  font-size: 12px;
}
.out-400-10 {
  font-family: Outfit, sans-serif;
  font-weight: 400;
  font-size: 10px;
}

.out-400-14 {
  font-family: Outfit, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.out-400-16 {
  font-family: Outfit, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.out-400-24 {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

.out-300-10 {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 10px;
}

.out-300-12 {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 12px;
}

.out-300-14 {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 14px;
}
.out-300-20 {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.out-700-14 {
  font-family: Outfit, sans-serif;
  font-weight: 700;
  font-size: 14px;
}
