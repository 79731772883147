@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./Modules/Vault/file-preview.css";
@import "./fontStyles.css";

* {
  font-family: Outfit, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* ::-webkit-scrollbar {
  width: 0;
  background: transparent;
  height: 0;
} */

/* .App {
  height: 100vh;
} */

p {
  margin-bottom: 0px !important;
}

.custom-menu-dropdown.ant-dropdown-menu {
  border-radius: 8px !important;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f9fbfe !important;
}

a:hover {
  color: #165abf;
}

.overview-tab .ant-tabs-tab {
  padding-bottom: 4px !important;
  padding-top: 0px !important;
}

.mon-500-10 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 10px;
}

.mon-500-12 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 12px;
}
.mon-500-14 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.mon-500-16 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.mon-500-18 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.mon-500-20 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.mon-600-20 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 20px;
}
.mon-600-30 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 30px;
}
.mon-600-12 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 12px;
}
.mon-600-14 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.mon-600-18 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.mon-600-24 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 24px;
}
.mon-500-20 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.mon-500-30 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
}

.mon-400-12 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 12px;
}
.mon-400-10 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 10px;
}

.mon-400-14 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.mon-400-16 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.mon-400-24 {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

/* *::-webkit-scrollbar {
  width: 0px;
  height: 4px;
  border-radius: 10px;
} */

*::-webkit-scrollbar-thumb {
  background: #dde5ed;
  border-radius: 10px;
  min-height: 4px !important;
}

.font-nunito-sans {
  font-family: "Nunito Sans", sans-serif;
}

.project-progress-container {
  box-shadow: 2px 3px 6px rgba(16, 24, 40, 0.06);
}

.custom-drag-drop .ant-upload-wrapper .ant-upload-drag {
  background-color: #f5faff !important;
  border-radius: 8px !important;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload-btn {
  padding: 10px !important;
}

/* li[aria-selected="true"]{
  background-color: #F0F5FA !important;
} */

.ant-dropdown-menu > .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: #f0f5fa !important;
}

.ant-dropdown-menu > li:first-child {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ant-dropdown-menu > li:last-child {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ant-dropdown-menu > li:not(:first-child):not(:last-child) {
  border-radius: 0 !important;
}

.radial-blue-card {
  background: radial-gradient(
    100% 80% at 2.5% 0%,
    #2e90fa 0%,
    #1761cb 98.16%,
    #1359c4 98.42%
  );
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.radial-blue-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    100% 80% at 2.5% 0%,
    #2e90fa 0%,
    #1761cb 98.16%,
    #1359c4 98.42%
  );
  transition: all 0.5s ease-out;
}

.radial-blue-card:hover:before {
  transform: scale(5);
}

.radial-grey-card {
  background: radial-gradient(
    100% 80% at 2.5% 0%,
    #f9fbfe 0%,
    #eaf0f7 98.16%,
    #e2eaf2 98.42%
  );
  overflow: hidden;
  position: relative;
  z-index: 0;
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
}

.radial-grey-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    100% 80% at 2.5% 0%,
    #f9fbfe 0%,
    #eaf0f7 98.16%,
    #e2eaf2 98.42%
  );
  transition: all 0.5s ease-out;
}

.radial-grey-card:hover:before {
  transform: scale(5);
}

@keyframes text-show {
  from {
    opacity: 0;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-bottom: 1.5rem;
  }
}
@keyframes text-show-project {
  from {
    opacity: 0;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-bottom: 2rem;
  }
}

.text-show-animation {
  margin-bottom: 1.5rem;
  opacity: 1;
  animation-name: text-show;
  animation-duration: 2s;
}

.text-show-animation.text-gap-sdg {
  margin-bottom: 0 !important;
  opacity: 1;
  animation-name: text-show;
  animation-duration: 2s;
}

.text-show-animation-project {
  margin-bottom: 2rem;
  opacity: 1;
  animation-name: text-show-project;
  animation-duration: 1s;
}

.hexagon-list:nth-child(even) {
  padding-left: 48px;
}
.hexagon-list:first-child {
  right: 55px;
}
.hexagon {
  position: relative;
  float: left;
  width: 94px;
  height: 104px;
  padding: 2px;
  border-radius: 10px;
  box-sizing: border-box;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  cursor: pointer;
}

.unset-wnh {
  width: unset !important;
  height: unset !important;
}
.hex-two-row:nth-child(even) {
  padding-left: 22px !important;
}

.placeholder-hexagon {
  visibility: hidden;
}

.transform-new {
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
  will-change: transform-new;
}

.start-animate-to-top {
  bottom: 0rem;
}
.animate-to-top {
  bottom: 12rem;
}
/* .hexagon:hover {
  background-color: #149d8b;
}
.hexagon:hover:before {
  border-bottom-color: #149d8b;
}
.hexagon:hover:after {
  border-top-color: #149d8b;
} */
/* .hexagon:before,
.hexagon:after {
  position: absolute;
  content: "";
  border-left: 47px solid transparent;
  border-right: 47px solid transparent;
  left: 0;
  right: 0;
  border-radius: 1px;
} */

/* .hexagon:before {
  margin-bottom: -1px;
  transition: border-bottom-color 0.5s ease;
  bottom: 100%;
  border-radius: 3px;
}

.hexagon:after {
  margin-top: -1px;
  transition: border-top-color 0.5s ease;
  top: 100%;
  border-radius: 3px;
} */

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scroll-show::-webkit-scrollbar {
  display: block !important;
  scrollbar-width: auto !important;
}
.scroll-show {
  /* display: block !important; */
  scrollbar-width: auto !important;
}

.hide-scrollbar {
  /* IE and Edge */
  -ms-overflow-style: none !important;
  /* Firefox */
  scrollbar-width: none !important;
}

.jumping-element {
  position: absolute;
  bottom: 50px;
  left: 25%;
  transform: translateX(-75%);
  cursor: pointer;
  animation: jump 2s ease-in-out infinite;
}

@keyframes jump {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}

.jumping-element.hover {
  animation-play-state: paused;
}

/**
 * ==============================================
 * Dot Plus
 * ==============================================
 */

/* .dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
} */

/**
 * ==============================================
 * Dot Collision
 * ==============================================
 */
.dot-collision {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #98a2b3;
  color: #98a2b3;
}
.dot-collision::before,
.dot-collision::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-collision::before {
  left: -7px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #98a2b3;
  color: #98a2b3;
  animation: dot-collision-before 2s infinite ease-in;
}
.dot-collision::after {
  left: 7px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #98a2b3;
  color: #98a2b3;
  animation: dot-collision-after 2s infinite ease-in;
  animation-delay: 1s;
}

@keyframes dot-collision-before {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-15px);
  }
}
@keyframes dot-collision-after {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px);
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 10px;
  /* overflow-x: scroll; */
  /* overflow: visible; */
  /* width: 500px; */
  grid-auto-flow: column;
  grid-auto-columns: minmax(150px, 1fr);
}

.grid-item {
  background-color: lightgray;
  border: 1px solid black;
  text-align: center;
}

.kanban-col > div:last-child > div {
  margin-bottom: 16px;
}
/*
.kanban-col > div:first-child > div{
  margin-top: 0px !important;
} */

.ant-select-tree-switcher {
  line-height: 28px;
}

.ant-select-tree .ant-select-tree-node-content-wrapper {
  line-height: 2.1875rem;
}

/**
 * ===================================================================================================================================================
 * Waddle V2 CSS 
 * ===================================================================================================================================================
 */

.ant-drawer-body {
  padding: 0px !important;
}

.sidebar-collapse .ant-collapse-header,
.sidebar-collapse .ant-collapse-content-box {
  padding: 0px !important;
  border-radius: 8px !important;
}

.anticon {
  display: flex !important;
  justify-items: center;
  align-items: center;
}

.ant-layout-sider {
  transition: all 0.2s;
}

.ant-popover .ant-popover-inner {
  padding: 0px !important;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0.4798rem 0.75rem;
}

.custom-search-input .ant-input,
.custom-search-input .ant-input::placeholder {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #667085;
}

.custom-upload-hide.ant-upload-picture-card-wrapper {
  display: none;
}

.custom-upload.ant-upload-picture-card-wrapper {
  padding: 8px 16px;
}

.ant-upload-list-item {
  border-radius: 8px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 56px;
  width: 56px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container {
  height: 56px;
  width: 56px;
}

.ant-upload-list-picture-card-container {
  height: 56px;
  width: 56px;
}

.ant-upload.ant-upload-select {
  height: 56px !important;
  width: 56px !important;
  border-radius: 8px !important;
}

.createGroupModalV3 .ant-upload.ant-upload-select {
  height: 165px !important;
  width: 165px !important;
}
.ant-mentions:hover {
  border-inline-end-width: 0px;
}

.ant-mentions:focus,
.ant-mentions-focused {
  box-shadow: none;
  border-inline-end-width: 0px;
  outline: 0;
}

.ant-mentions > textarea,
.ant-mentions > textarea::placeholder {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #667085;
  padding-left: 0px;
}

.comments-textarea {
  width: inherit;
}
.comments-textarea__control {
  height: 100%;
}
.comments-textarea__input {
  background-color: #fff;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
  padding: 16px 17px;
  font-size: 14px;
  font-style: Outfit;
  line-height: 1.42857143;
  color: #292927;
  font-weight: 300;
  border: none;
  top: 2.5px !important;
}
.comments-textarea__input:focus,
.comments-textarea__input:active {
  outline: none;
  border: none;
}
.comments-textarea__input::placeholder {
  color: #667085;
  font-size: 14px;
  font-weight: 300;
  font-style: Outfit;
}
.comments-textarea__highlighter {
  padding: 16px 17px;
  min-height: 52px;
}
.comments-textarea__suggestions__list {
  background: #f9fbfe;
  border-radius: 8px;
  max-height: 284px;
  /* width: 120px; */
  overflow-y: scroll;
}
.comments-textarea__suggestions__item {
  padding: 0px 14px;
  padding-bottom: 10px;
}
.comments-textarea__suggestions__item:hover {
  background-color: #eff8ff;
}
.comments-textarea__suggestions__item:active {
  background-color: #eff8ff;
}
.comments-textarea__suggestions__item[aria-selected="true"] {
  background-color: #eff8ff;
}
.comments-textarea__suggestions__item:first-child {
  padding-top: 15px;
}
.comments-textarea__suggestions__item:last-child {
  padding-bottom: 15px;
}
.comments-textarea__suggestions__item:not(:first-child) {
  padding-top: 10px;
}
.comments-textarea__suggestions {
  background: #f9fbfe;
  border: 1px solid #edf3f9;
  border-radius: 8px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
.comments-textarea__suggestions__item__focused {
  background-color: #daf4fa;
}

/* // Custom Unput */
.shadow-input-focus {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #d1e9ff;
}

.shadow-input-error {
  box-shadow: 0px 0px 0 4px rgba(253, 162, 155, 0.3);
}

.custom-input-box {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #101828;
}

.custom-input-box::placeholder,
.custom-select-input::placeholder {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #98a2b3;
}

.task-input {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #101828;
}

.task-input::placeholder {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #667085;
}
.notes-input > input {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #101828 !important;
}

.notes-input > input::placeholder {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #667085 !important;
}

.task-description {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #101828;
}

.task-description::placeholder {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #667085;
}

.subtask-input {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #292927;
}

.subtask-input::placeholder {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #667085;
}

.new-task-textarea {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #292927;
}

.new-task-textarea::placeholder {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #98a2b3;
}

.custom-select-input .ant-select-selection-placeholder {
  font-family: Outfit, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #667085;
}
.ant-select-dropdown .ant-select-item-group {
  padding: 0px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .custom-calendar .ant-picker-panel {
  background-color: #f9fbfe !important;
} */
.custom-calendar .ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 0px !important;
}
.custom-calendar .ant-picker-calendar .ant-picker-panel {
  /* background-color: #f9fbfe !important; */
  border-top: 0px !important;
}
.custom-calendar
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  border-radius: 50%;
  color: #f9fbfe;
}
.custom-calendar
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-radius: 50%;
}
.custom-calendar .ant-picker-cell {
  font-size: 14px;
  color: #98a2b3;
  font-weight: 500;
}
.custom-calendar .ant-picker-cell-in-view {
  font-size: 14px;
  color: #292927;
  font-weight: 500;
}
.custom-calendar .ant-picker-content th {
  font-size: 14px;
  color: #292927;
  font-weight: 500;
  height: 31px;
}

.roadmap-calendar .ant-picker-calendar .ant-picker-panel {
  background-color: #f9fbfe !important;
}

.custom-header .ant-table-thead .ant-table-cell {
  background-color: #f7fafd !important;
}

.custom-header .ant-table-cell {
  border: unset !important;
}

.custom-header .ant-table-pagination.ant-pagination {
  display: none !important;
}

.flexible-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(173px, 173px));
  grid-gap: 16px;
}

/* Custom scrollbar */

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.custom-scrollbar-project-bar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f9fbfe;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #dde5ed;
  border-radius: 8px;
}
/* .ant-carousel .slick-prev, .ant-carousel .slick-prev:hover, .ant-carousel .slick-prev:focus {
  font-size: inherit;
  left: 10px;
  z-index: 2;
}
.ant-carousel .slick-next, .ant-carousel .slick-next:hover, .ant-carousel .slick-next:focus {
  font-size: inherit;
  right: 10px;
  z-index: 2;
} */
.ant-carousel .slick-next::before {
  content: "";
}
.ant-carousel .slick-prev::before {
  content: "";
}

.ant-tooltip-inner {
  background-color: #0d3271 !important;
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 12px;
}

.ant-tooltip {
  --antd-arrow-background-color: #0d3271;
}

.custom-select-input.ant-select-multiple .ant-select-selector {
  width: 100%;
}

.custom-select-input.ant-select-multiple
  .ant-select-selector
  .ant-select-selection-item {
  align-items: center;
  background: #edf3f9;
  border-radius: 16px;
  padding-inline-start: 4px;
  padding-inline-end: 10px;
  border: none;
}

/* .custom-table .ant-table.ant-table-bordered > .ant-table-container {
  border-left: unset;
}
.custom-table table {
  border-top: unset !important;
  border-radius: 0 0 0 0;
}
.custom-table .ant-table {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.custom-table .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0px !important;
}
.custom-table .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 0px !important;
}
.custom-table .actions {
  display: none;
}
.custom-table .ant-table-row:hover .actions {
  display: block;
}
.custom-table div.ant-table-body {
  transition: all 0.5s ease-in-out;
}
.custom-table .ant-table-row {
  height: 44px !important;
}
.custom-table .ant-table-tbody > tr > td {
  border-bottom: 0px;
}
.custom-table .ant-table-thead > tr > th {
  border-bottom: 1px solid #dde5ed;
} */

.ant-table-cell {
  padding: 10.5px 23px !important;
  background-color: #f9fbfe !important;
  border-inline-end: unset !important;
}
.ant-table-thead .ant-table-cell {
  padding: 13px 23px !important;
}

.ant-table-column-sorters {
  justify-content: start !important;
}
.ant-table-column-title {
  flex: unset !important;
}
.ant-table-column-sorter-inner {
  /* color: #98a1b5 !important; */
}

.ant-table-wrapper .ant-table-column-sorter-up,
.ant-table-wrapper .ant-table-column-sorter-down {
  font-size: 9.66px !important;
  /* color: #98a1b5 !important; */
}

.ant-table-wrapper
  .ant-table-column-sorter
  .ant-table-column-sorter-up
  + .ant-table-column-sorter-down {
  margin-top: -0.3em !important;
}
[class^="ant-table"] [class^="ant-table"],
[class*=" ant-table"] [class^="ant-table"],
[class^="ant-table"] [class*=" ant-table"],
[class*=" ant-table"] [class*=" ant-table"] {
  border-radius: 0px !important;
}

/* we are using antd input at many places, this code affects all the components , unsets the shadow of those components and the border-color gets customised to this setting*/
/* .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #7dc7ff !important;

  box-shadow: unset !important;
} */
.ant-input-affix-wrapper .ant-input-clear-icon {
  display: none !important;
}

.clearfix::before {
  display: table;
  content: "";
}
.clearfix::after {
  display: table;
  clear: both;
  content: "";
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid #dde5ed !important ;
}

.ant-segmented .ant-segmented-item-selected {
  background: #f9fbfe !important;
  /* Shadow/sm */

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06) !important;
  border-radius: 6px !important;
}

.ant-segmented {
  background: #f0f5fa !important;
  /* Gray/100 */

  border: 1px solid #edf3f9 !important;
  border-radius: 8px !important;
}
.ant-segmented .ant-segmented-item:hover {
  background: transparent !important;
}

.ant-picker .ant-picker-disabled {
  background: #f0f5fa !important;
  border-color: #dde5ed !important;
}
.ant-picker .ant-picker-disabled {
  background: #f0f5fa !important;
  border-color: #dde5ed !important;
}
.ant-picker .ant-picker-input {
  color: black !important;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}
.ant-picker .ant-picker-input > input[disabled] {
  color: #667085 !important;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

.ant-picker-input > input::placeholder {
  font-family: Outfit, sans-serif !important;
  font-weight: 300;
  font-size: 14px;
  color: #667085 !important;
}

.avatar-group {
  display: flex;
  justify-content: center;
}
.avatar {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  box-sizing: content-box;
  transition: transform ease 300ms;
}
.avatar-group .avatar:not(:first-child) {
  margin-left: -8px;
}
.avatar-group .avatar {
  border: 1px solid #fff;
}
.avatar:hover {
  transform: translate(0, -5px);
}

.planner-divider:hover
  .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.planner-divider:hover
  .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-color: #101828;
}
.planner-divider .ant-divider-inner-text {
  padding: 0px;
}
/* 
::-webkit-scrollbar {
  width: 8px;
  padding-right: 4px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: transparent;
} */

/* .overflow-y-scroll:hover::-webkit-scrollbar {
  width: 8px;
  padding-right: 4px;
  display: block;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  min-height: 40px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #dfdfdf;
} */

/* checkbox css  */

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #165abf !important;
  background: #eff8ff !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #165abf;
  height: 2px;
  width: 8.5px;
  border-radius: 1px;
}

.ant-checkbox-checked::after {
  border-color: #165abf !important;
}

.ant-checkbox .ant-checkbox-inner:after {
  border: 2px solid #165abf;
  border-top: 0px;
  border-left: 0px;
  border-radius: 1px;
}

.ant-checkbox .ant-checkbox-inner {
  background-color: transparent;
}

.ant-checkbox-wrapper:hover .ant-checkbox:after {
  visibility: hidden;
}

.already-added-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: #98a2b3 !important;
  border-color: #98a2b3 !important;
  border-width: 2px #98a2b3 !important;
}

.already-added-checkbox .ant-checkbox .ant-checkbox-inner:after {
  border: 2.5px solid #fff !important;
  border-top: 0px !important;
  border-left: 0px !important;
}

/* Override specific styles for Checkbox with class messagesV3 */

.messageV3 .ant-checkbox .ant-checkbox-inner:after {
  border: 2.5px solid #fff !important;
  border-top: 0px !important;
  border-left: 0px !important;
  right: 0px !important;
  left: 4px !important;
}

.messageV3 .ant-checkbox-checked .ant-checkbox-inner {
  background: #1677ff !important;
  border-color: #1677ff !important;
  border-width: 2px !important;
  border-left: 0px !important;
}
.messageV3 .ant-checkbox-inner {
  border-width: 2px !important;
  border-color: #ccc;
}

.createEventV3 .ant-checkbox .ant-checkbox-inner:after {
  border: 2.5px solid #fff !important;
  border-top: 0px !important;
  border-left: 0px !important;
}

.createEventV3 .ant-checkbox-checked .ant-checkbox-inner {
  background: #1677ff !important;
  border-color: #1677ff !important;
  border-width: 2px !important;
}
.createEventV3 .ant-checkbox-inner {
  background: #fff;
  border-width: 2px !important;
  border-color: #ccc;
}

.calendarV3 .ant-checkbox .ant-checkbox-inner:after {
  border: 2.5px solid #fff !important;
  border-top: 0px !important;
  border-left: 0px !important;
  right: 0px !important;
  left: 2px !important;
}

.calendarV3 .ant-checkbox-checked .ant-checkbox-inner {
  background: #ffdb01 !important;
  border-color: #ffdb01 !important;
  border-width: 2px !important;
  border: auto !important;
}
.calendarV3 .ant-checkbox-inner {
  border-width: 2px !important;
  border-color: #ccc;
}

/* Loading Progress Bar  */

.progress-materializecss {
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  display: block;
  width: 100%;
  background-color: #e2ecfe;
  border-radius: 2px;

  overflow: hidden;
  z-index: 999999;
}

.progress-materializecss .indeterminate {
  background-color: #387ef5;
}

.progress-materializecss .indeterminate:before {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress-materializecss .indeterminate:after {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  animation-delay: 1.15s;
}

/* Adjust the overall container of the progress bar, if necessary */
.accordionProgressBar .ant-progress-outer {
  width: 100%;
  height: 24px; /* Match the height of the progress bar for consistency */
}

/* Directly target the progress bar's background */
.accordionProgressBar .ant-progress-bg {
  width: 100%; /* Ensure the progress bar fills the container */
  height: 24px; /* Adjust as necessary to match your design */
  background-color: #f472b6; /* Apply your desired progress bar color */
  border-radius: 40px; /* Adjust for rounded corners */
}

/* If using clip-path for a custom appearance */
.accordionProgressBar .ant-progress-bg::before {
  content: "";
  display: block;
  height: 100%;
  clip-path: inset(0px calc(100% - var(--progress-percentage)) 0px 0px);
  background-color: #f472b6; /* Adjust the color as needed */
  border-radius: 40px; /* Ensure the corners are rounded */
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
.ant-checkbox-inner::after {
  width: 5.5px;
  height: 9.5px;
  top: 46%;
}

/* antd dropdown padding */
/* .ant-dropdown .ant-dropdown-menu .project-progress-dropdown {
  padding: 0px !important;
} */
.no-suffix.ant-input-suffix {
  display: none;
}
.checklist-ant-input .ant-input {
  background-color: inherit !important;
}

.recharts-yAxis {
  transform: translate(0, 0);
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #165abf;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #165abf;
  background-color: #fff;
}

.custom-footer .ant-table-footer {
  padding: 0 !important;
  background: transparent;
}
.custom-search-box .ant-input-suffix {
  display: none !important;
}
.custom-search-input .ant-input-focus {
  border: #7dc7ff !important;
}

.custom-otp-input {
  width: 44px !important;
  text-align: center;
  height: 44px;
  border: 1px solid #c0cbd7;
  border-radius: 4px;
  margin-right: 19px;
}
.custom-otp-input:focus {
  box-shadow: 0px 0px 0 4px rgba(125, 199, 255, 0.4) !important;
  outline: none !important;
}
.custom-otp-input:focus-visible {
  box-shadow: 0px 0px 0 4px rgba(125, 199, 255, 0.4) !important;
  outline: none !important;
}

.custom-range-picker-for-onboarding.ant-picker {
  padding: 0px 0px 0px 0px !important;
  width: 70%;
}
.custom-range-picker-for-onboarding
  .ant-picker-range.ant-picker-focused
  .ant-picker-active-bar {
  opacity: 0 !important;
}
.custom-range-picker-for-onboarding .ant-picker-active-bar {
  opacity: 0 !important;
}
.custom-range-picker-for-onboarding .ant-picker-input input {
  font-size: 12px !important;
  color: #101828;
  font-family: "Outfit";
  font-weight: 500;
}

.ant-table-cell-row-hover {
  background-color: #dde5ed !important;
  cursor: pointer !important;
}

.ant-switch {
  background-color: #edf3f9 !important;
}

.ant-switch.ant-switch-checked {
  background-color: #165abf !important;
}
.ant-pagination {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.ant-pagination .ant-pagination-item-active {
  display: flex !important;
  width: 33px !important;
  height: 32px !important;
  padding: 12px 8px !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  align-self: stretch !important;

  border-radius: 20px !important;
  color: #165abf !important;
  /* Text xs/Medium */
  font-size: 12px !important;
  font-family: Outfit !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  border: 0px !important;
  background-color: #ecf1fa !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #165abf !important;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item a {
  color: #98a2b3;

  /* Text xs/Medium */
  font-size: 12px !important;
  font-family: Outfit !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}

/* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector  {
  padding: 0px !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  inset-inline-start: 0px !important;
  inset-inline-end: 0px !important;
}
.ant-select-disabled
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  cursor: allowed !important;
}
.ant-select-show-search
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  color: black !important;
} */

.custom-address-input-ob .ant-select-selector {
  padding-left: 0px !important;
}
.custom-address-input-ob .ant-select-selector .ant-select-selection-search {
  left: 0px !important;
}
.custom-address-input-ob .ant-select-selector {
  left: 0px !important;
  right: 0px !important;
}

.custom-address-input-ob .ant-input,
.custom-address-input-ob .ant-input::placeholder {
  font-family: Outfit, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
}

.custom-message .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  align-items: center;
}
.custom-message
  .ant-message-notice-content
  .ant-message-custom-content
  .anticon {
  top: 0px;
}

.image-crop-modal .ant-modal-content {
  border-radius: 12px;
  padding: 0px;
}
.image-crop-modal .ant-modal-content .ant-modal-header {
  border-radius: 12px 12px 0 0;
}
.image-crop-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.image-crop-modal .ant-modal-content .ant-modal-footer button {
  border-radius: 6px;
  font-weight: 500;
}
.image-crop-modal .ant-modal-content .ant-modal-footer .ant-btn-primary {
  background: #165abf;
}

.image-crop-modal .ant-slider .ant-slider-track {
  background-color: #165abf;
}

.image-crop-modal .ant-modal-body {
  padding: 0px;
}

.image-crop-modal .ant-modal-header {
  padding: 16px;
  margin-bottom: 0px;
}

.image-crop-modal .ant-modal-footer {
  padding: 16px;
  margin-top: 0px;
}

.ant-avatar {
  border: none;
}

.member-table-pagination .anticon {
  display: block !important;
}

.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled):hover {
  background-color: #fef3f2 !important ;
  color: #b42318;
}

.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled):hover
  > .ant-dropdown-menu-title-content
  > *,
.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled):hover
  > * {
  color: #b42318 !important;
}

.ant-switch-handle {
  top: -3px !important;
}
.ant-switch.beforeSwitch .ant-switch-handle::before {
  background-color: #98a2b3 !important;
}
.ant-switch.afterSwitch .ant-switch-handle::before {
  background-color: #2e90fa !important;
}
.ant-switch.beforeSwitch .ant-switch-handle {
  inset-inline-start: 0px !important;
}
.ant-switch.ant-switch-small .ant-switch-handle {
  width: 14px !important;
  height: 14px !important;
}
.ant-switch.ant-switch-checked {
  background-color: #aadaff !important;
}
.ant-switch.ant-switch-unchecked {
  background-color: #dde5ed !important;
}
.ant-switch.ant-switch-small {
  min-width: 24px !important;
  height: 8px !important;
  line-height: 16px;
  top: 8px !important ;
}
/* .helelooo {
  background: rgba(255, 255, 255, 0.2); 
  backdrop-filter: blur(8px);
  height: 100vh;
	width: 50%;
} */

.custom-pagination .ant-pagination-item {
  display: none !important;
}

/* .custom-picker .ant-picker-input {
  /* display: none !important; */
/* visibility: hidden; */
/* width: 0px; */
/* } */

/* .custom-picker .ant-picker-range-separator {
  display: none !important;
} */

/* .custom-picker {
  padding: 0px !important;
  border: 0px !important;
} */

.rmdp-week-day {
  color: #344054 !important;
}
/* .rmdp-arrow {
  border: solid #667085 !important;
} */

.rmdp-day.rmdp-today span {
  border: 2px solid #165abf !important ;
  border-radius: 100% !important;
  background-color: #ffffff !important;
  color: black !important;
}
.rmdp-day.rmdp-today.rmdp-range.start span {
  background-color: #165abf !important;
  color: white !important;
}
.rmdp-day.rmdp-today.rmdp-range.end span {
  background-color: #165abf !important;
  color: white !important;
}
.rmdp-day.rmdp-range.start {
  background-color: #165abf !important;
  color: white !important;
}

.rmdp-day.rmdp-range.start {
  border-radius: 50% !important;
  background-color: #165abf !important;
  color: #f9fbfe !important;
}
.rmdp-day.rmdp-range.end {
  border-radius: 50% !important;
  background-color: #165abf !important;
  color: #f9fbfe !important;
}
.rmdp-day.rmdp-range {
  background-color: #eff8ff !important;
  color: #144ea6 !important;
  border: none !important;
}
.rmdp-range {
  box-shadow: none !important;
}
.custom-header-timeline .ant-table-thead .ant-table-cell {
  background-color: #f1f5f9 !important;
  cursor: default !important;
}

.custom-header-timeline .ant-table-cell-row-hover {
  background-color: #f9fbfe !important;
  cursor: default !important;
}

.event-info .ql-editor {
  padding: 0px;
}

.strike-line {
  position: absolute;
  height: 1px;
  background-color: red;
  pointer-events: none;
}

.ant-picker-ok button {
  background-color: #165abf !important;
  color: white !important;
}
.ant-picker-ok button:hover {
  background-color: #165abf !important;
  color: white !important;
}

.recharts-layer.recharts-active-dot {
  z-index: 60 !important;
}
.recharts-layer .recharts-line-dots {
  z-index: 60 !important;
}

.recharts-surface {
  overflow: visible !important;
  z-index: 30 !important;
}

.recharts-cartesian-grid-horizontal > :first-child {
  /* CSS styles for the first child */
  stroke: #969696 !important;
  z-index: -30 !important;
}
.tip {
  opacity: 1;
  transition: opacity 5s ease;
}

.tip.hidden {
  opacity: 0;
}
.ql-toolbar.ql-snow {
  border-color: #dde5ed !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ql-container.ql-snow {
  border-color: #dde5ed !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ql-snow .ql-stroke {
  stroke: #98a2b3 !important;
}
.ql-snow .ql-fill {
  fill: #98a2b3 !important;
}
.calendar-select .ant-select-selector {
  background-color: #f9fbfe !important;
}
.month-view-event-cell:hover {
  background-color: #7dc7ff !important;
}
.month-hover-border:hover {
  border-color: #7dc7ff !important;
  /* border-width: 2px !important; */
}
.more-options-calendar .ant-dropdown.ant-dropdown-menu {
  background-color: #edf3f9 !important;
}

/* .carousel-dots {
  position: absolute;
  bottom: -30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel-dots li button {
  width: 10px;
  height: 10px;
  background-color: gray;
  border-radius: 50%;
}

.carousel-dots li.slick-active button {
  background-color: black;
}

.slick-dots li button {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
.slick-dots li.slick-active button {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: red !important;
} */


.export-layout .CustomCarousel {
  flex-direction: column;
  margin-bottom: 16px; /* Adjust margin between carousel and charts */
}

.export-layout .charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.export-layout .chart {
  flex: 1 1 25%; /* Ensures 4 charts per row */
  min-width: 200px; /* Optional: Add a minimum width for the charts */
}

/* Custom checkbox hover effect */
.custom-checkbox .ant-checkbox-inner {
  border-color: #d9d9d9; /* Default border color */
  transition: border-color 0.3s;
}

/* Hover state when the parent div is hovered */
.group:hover .custom-checkbox .ant-checkbox-inner {
  border-color: #1890ff; /* Blue border on hover */
}
